import httpParseQuery from '@frontastic/common/src/js/httpParseQuery';
import VisibilityChange from '@frontastic/common/src/js/visibilityChange';
import { Route } from 'next';
import { NextRouter } from 'next/router';
import { store } from '../../../redux/store';
import Api from './api';
import Loader from './loader';
import Router from './router';

class App {
  private initialized = false;
  private store = undefined;
  private router: Router | undefined = undefined;
  private visibility: any | undefined = undefined;
  public api: Api | undefined = undefined;
  private loader: Loader | undefined = undefined;

  initialize(nextRouter: NextRouter, context?: unknown) {
    if (this.initialized) {
      return;
    }

    this.store = store;
    this.router = new Router(nextRouter, context);
    this.api = new Api(this.router, this.store);
    this.loader = new Loader(this.store, this.router, this.api);

    this.visibility = new VisibilityChange();
    this.visibility.registerCallBack(this.api.pauseRequests, this.api.resumeRequests);

    this.loader
      .getLoader('cart')
      .get()
      .then(() => this.loader.getLoader('wishlist').get());

    this.store.dispatch({
      type: 'Frontend.App.initialize',
      data: this,
    });

    this.initialized = true;
  }

  loadForLocation(location) {
    this.loader.loadContentForPath(
      location.pathname,
      httpParseQuery(location.search.substring(1)),
      location.state || null,
    );
  }

  getApi(): Api {
    if (this.api === undefined) {
      throw new Error('The app has not yet been initialized. Please use the <CatwalkProvider>');
    }

    return this.api;
  }

  getRouter(): Router {
    if (this.router === undefined) {
      throw new Error('The app has not yet been initialized. Please use the <CatwalkProvider>');
    }

    return this.router;
  }

  getLoader(name): any {
    if (this.loader === undefined) {
      throw new Error('The app has not yet been initialized. Please use the <CatwalkProvider>');
    }

    return this.loader.getLoader(name);
  }

  getStore() {
    return this.store;
  }
}

export default new App();
