import React, { ComponentProps, useEffect } from 'react';
import injection from '../../../legacy/js/injection'; // Include all injections before tastics
import { useRouter } from 'next/router';
import dot from 'dot-object';
import { IntlProvider } from 'react-intl';
import { Provider } from 'react-redux';
import app from './app/app';

// we have to call this function so the import won't be optimized away.
injection();

import Notifications from '@frontastic/catwalk/js/component/notifications';
import AppContainer from './appContainer';
import FrontasticStarter from '../../../pages/_app';
import { store } from '../../redux/store';
import useFrontasticBridgeReduxDispatches from '../../redux/useFrontasticBridgeReduxDispatches';

type DotInput = {
  [key in string]: string | DotInput;
};

type Props = Omit<ComponentProps<typeof FrontasticStarter>, 'Component' | 'router'> & { messages: DotInput };

// @COFIXME[dev](FLBML-132): Decide if you want to keep this in production
const PagePropsDebugOutput: React.FunctionComponent<{ pageProps: any }> = ({ pageProps }) => {
  const router = useRouter();

  if (!router.query.debugPageProps) {
    return null;
  }

  return <pre>{JSON.stringify(pageProps, null, 2)}</pre>;
};

const CatwalkProviderImpl: React.FunctionComponent<Props> = ({ children, pageProps, messages }) => {
  const router = useRouter();
  const locale = pageProps.locale?.split('_')[0];

  useFrontasticBridgeReduxDispatches(
    router,
    // @COFIXME[typescript](FLBML-133): This breaks because redirectresponse and responseerror do not have the correct `data`.
    // I am not sure if these two cases would actually end up here, but don't want to dig down that rabbit hole right now.
    // @ts-expect-error
    pageProps.data,
    pageProps.locale,
    pageProps.userAgent,
    pageProps.contextData,
  );

  useEffect(() => {
    const callback = () => {
      app.loadForLocation(window.location);
    };

    router.events.on('routeChangeComplete', callback);

    return () => router.events.off('routeChangeComplete', callback);
  }, []);

  if (!('pageFolder' in pageProps.data)) {
    return null;
  }

  const pageFolderType = pageProps.data.pageFolder?.pageFolderType || '';
  const customClassname = pageProps.data.pageFolder?.configuration?.displayClassname || '';

  return (
    <Provider store={store}>
      <IntlProvider locale={locale} messages={dot.dot(messages[locale] || {})} textComponent="span">
        <AppContainer>
          <div className={`s-node s-node--${pageFolderType} ${customClassname}`}>{children}</div>
          <Notifications />
        </AppContainer>
      </IntlProvider>
    </Provider>
  );
};

export const CatwalkProvider: React.FunctionComponent<Props> = ({ children, messages, pageProps }) => {
  const router = useRouter();

  if (
    !pageProps.data ||
    typeof pageProps.data === 'string' ||
    ('ok' in pageProps.data && 'message' in pageProps.data && !pageProps.data.ok && pageProps.data.message) ||
    'error' in pageProps ||
    router.asPath.split('?')[0] === '/404'
  ) {
    return (
      <>
        {children}
        <PagePropsDebugOutput pageProps={pageProps} />
      </>
    );
  }

  return (
    <CatwalkProviderImpl pageProps={pageProps} messages={messages}>
      {children}
      <PagePropsDebugOutput pageProps={pageProps} />
    </CatwalkProviderImpl>
  );
};
