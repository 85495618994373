import { NextRouter } from 'next/router';
import httpBuildQuery from '@frontastic/common/src/js/httpBuildQuery';
import pathToRegexp from 'path-to-regexp';
import levenshtein from 'fast-levenshtein';

class RouterHistory {
  nextRouter: NextRouter;

  constructor(nextRouter: NextRouter) {
    this.nextRouter = nextRouter;
  }

  push(path: string) {
    this.nextRouter.push(path);
  }

  replace(path: string) {
    this.nextRouter.replace(path);
  }
}

class Router {
  readonly nextRouter: NextRouter;
  readonly history: RouterHistory;
  context: any;
  readonly parameterMatcher: RegExp;

  constructor(nextRouter: NextRouter, context?: unknown) {
    this.nextRouter = nextRouter;
    this.history = new RouterHistory(nextRouter);
    this.context = context;
    this.parameterMatcher = /\{([A-Za-z0-9]+)\}/g;
  }

  setContext(context: unknown): void {
    this.context = context;
  }

  location(route: string, parameters = {}): { pathname: string; search: string } {
    const allParameters = {
      ...this.contextParameters(),
      ...parameters,
    };

    if (!this.hasRoute(route)) {
      throw new Error(
        'Route ' + route + ' not defined, did you mean any of these: ' + this.getSimilarRoutes(route).join(', '),
      );
    }

    const keys = [];
    let matches = [];
    while ((matches = this.parameterMatcher.exec(this.context.routes[route].path))) {
      // eslint-disable-line no-cond-assign
      keys.push(matches[1]);
    }

    const unknownKeys = [];
    for (const key of keys) {
      // eslint-disable-next-line no-prototype-builtins
      if (!allParameters.hasOwnProperty(key)) {
        unknownKeys.push(key);
      }
    }

    if (unknownKeys.length) {
      // eslint-disable-next-line no-console
      console.error('Missing values for ' + route + ': ' + unknownKeys.join(', '));
      return { pathname: '/', search: '' };
    }

    const query = {};
    for (const [key, value] of Object.entries(allParameters)) {
      if (!keys.includes(key)) {
        query[key] = value;
      }
    }

    let search = '';
    if (Object.keys(query).length > 0) {
      search = '?' + httpBuildQuery(query);
    }

    return {
      pathname: keys.reduce(function (link, key) {
        return link.replace('{' + key + '}', allParameters[key]);
      }, this.context.routes[route].path),
      search: search,
    };
  }

  path(route: string, parameters = {}): string {
    const location = this.location(route, parameters);

    return location.pathname + location.search;
  }

  hasRoute(route: string): boolean {
    return this.context.routes[route] !== undefined;
  }

  push(route, parameters = {}): void {
    const { pathname, search } = this.location(route, parameters);
    this.history.push(pathname + search);
  }

  replace(route, parameters = {}): void {
    const { pathname, search } = this.location(route, parameters);
    this.history.replace(pathname + search);
  }

  contextParameters() {
    return this.context.toParameters();
  }

  getSimilarRoutes(route) {
    const distances = Object.keys(this.context.routes).map((key) => {
      return { route: key, distance: levenshtein.get(key, route) };
    });

    distances.sort((a, b) => {
      return a.distance - b.distance;
    });

    return distances.slice(0, 5).map((value) => {
      return value.route;
    });
  }

  match(path: string) {
    for (const route in this.context.routes) {
      const matchResult = this.matchPath(path, this.reactRoute(route));

      if (matchResult) {
        return {
          route: route,
          parameters: matchResult.params,
        };
      }
    }

    throw new Error('No defined route match path: ' + path);
  }

  reactRoute(route) {
    if (!(route in this.context.routes)) {
      throw new Error(
        'Route ' + route + ' not defined, did you mean any of these: ' + this.getSimilarRoutes(route).join(', '),
      );
    }

    const keys = [];
    let matches: RegExpExecArray = undefined;
    while ((matches = this.parameterMatcher.exec(this.context.routes[route].path))) {
      // eslint-disable-line no-cond-assign
      keys.push(matches[1]);
    }

    const requirements = this.context.routes[route].requirements || {};

    return keys.reduce(function (link, key) {
      let keyRequirement = '';
      if (requirements[key]) {
        keyRequirement = '(' + requirements[key] + ')';
      }
      return link.replace('{' + key + '}', ':' + key + keyRequirement);
    }, this.context.routes[route].path);
  }

  private matchPath(pathname, path) {
    const paths = [].concat(path);

    return paths.reduce(function (matched, path) {
      if (!path && path !== '') {
        return null;
      }

      if (matched) {
        return matched;
      }

      const keys = [];
      const regexp = pathToRegexp(path, keys);
      const match = regexp.exec(pathname);

      if (!match) {
        return null;
      }

      const url = match[0];
      const values = match.slice(1);
      const isExact = pathname === url;

      if (!isExact) {
        return null;
      }

      return {
        path: path,
        // the path used to match
        url: path === '/' && url === '' ? '/' : url,
        // the matched portion of the URL
        isExact: isExact,
        // whether or not we matched exactly
        params: keys.reduce(function (memo, key, index) {
          memo[key.name] = values[index];
          return memo;
        }, {}),
      };
    }, null);
  }
}

export default Router;
