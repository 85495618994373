import { default as NextLink } from 'next/link';
import { useRouter } from 'next/router';
import omit from '@frontastic/common/src/js/helper/omit';

// @COFIXME[routing](FLBML-124): Create proper adapter for react-router
export const Link = ({ children, to, ...rest }) => {
  const childProps = omit(rest, ['to']);

  return (
    <NextLink href={to} {...childProps}>
      {children}
    </NextLink>
  );
};

export const useHistory = () => ({ location: useLocation() });
export const useLink = () => {};
export const useLocation = () => {
  const router = useRouter();

  return { pathname: router.pathname, search: router.query.toString(), asPath: router.asPath };
};
