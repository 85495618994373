import React, { useEffect } from 'react';
import ComponentInjector from './app/injector';

/**
 * This component is wrapped around most of the application,
 * and can be overwritten using the component injector.
 */
const AppContainer = ({ children }: React.PropsWithChildren<object>) => {
  return children;
};

export default ComponentInjector.return('AppContainer', AppContainer);
