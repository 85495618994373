import { StoreAction } from '../actions/StoreActions'

export type StoreState = {
    isSelect: boolean
    store: object[]
    themeStore?: string
}

export const initialGlobalState: StoreState = {
    isSelect: false,
    store: [],
    themeStore: null,
}

export default (
    globalState = initialGlobalState,
    action: StoreAction,
) => {
    switch (action.type) {
        case 'ACTION_SELECT_STORE':
            if (!action.data) {
                return globalState
            }

            return {
                ...globalState,
                isSelect: action.data.isSelect,
            }
        case 'ACTION_THEME_STORE':
            if (!action.data) {
                return globalState
            }

            return {
                ...globalState,
                themeStore: action.data.themeStore,
            }
        default:
            return globalState
    }
}
