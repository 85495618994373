import Entity from '@frontastic/catwalk/src/js/app/entity';
import UrlContext from '@frontastic/catwalk/src/js/app/urlContext';

interface NodeState {
  loading: boolean;
  error: any;
  trees: any;
  nodes: any;
  nodeData: any;
  nodeIds: any;
  pages: any;
  last: {
    node: any;
    data: any;
    page: any;
  };
  currentNodeId: unknown;
  currentCacheKey: string | undefined;
}

const initialState: NodeState = {
  loading: false,
  error: null,
  trees: {},
  nodes: {},
  nodeData: {},
  nodeIds: {},
  pages: {},
  last: {
    node: null,
    data: null,
    page: null,
  },
  currentNodeId: null,
  currentCacheKey: undefined,
};

type NodeInitializeAction = {
  type: 'Frontend.Node.initialize';
  node: any;
  page: any;
  data: any;
};

type FrontasticRouteAction = {
  type: 'FRONTASTIC_ROUTE';
  route: any;
  page: any;
  data: any;
};

type TrackingPageViewAction = {
  type: 'Frontend.Tracking.PageView';
  nodeId: string;
  data: any;
  isMasterPage: boolean;
};

export const trackingPageView = ({ nodeId, data, isMasterPage }): TrackingPageViewAction => {
  return {
    type: 'Frontend.Tracking.PageView',
    nodeId,
    data,
    isMasterPage,
  };
};

type NodeAction = TrackingPageViewAction | NodeInitializeAction | FrontasticRouteAction | { type: '@@INIT' };

export const nodeReducer = (state: NodeState = initialState, action: NodeAction): NodeState => {
  switch (action.type) {
    case 'FRONTASTIC_ROUTE':
      // @COFIXME(FLBML-138): UrlContext should be ComponentInjected
      const currentCacheKey = UrlContext.getActionHash(action.route);

      // Assume that the currentNode does not change.
      // This is particularly important for the first render cycle,
      // because it will (otherwise) we initialized to late and we will
      // get an intermitting empty page
      let currentNodeId = state.currentNodeId;

      // @ts-expect-error
      if (action.lastRoute && action.lastRoute.route !== action.route.route) {
        // We are apparently changing the node, so check if we have the node ID cached
        if (state.nodeIds[currentCacheKey]?.isComplete && state.nodeIds[currentCacheKey].isComplete()) {
          currentNodeId = state.nodeIds[currentCacheKey].data?.nodeId ?? null;
        } else {
          currentNodeId = null;
        }
      }

      return {
        loading: true,
        error: null,
        trees: Entity.purgeMap(state.trees),
        nodes: Entity.purgeMap(state.nodes),
        nodeData: Entity.purgeMap(state.nodeData),
        nodeIds: Entity.purgeMap(state.nodeIds),
        pages: Entity.purgeMap(state.pages),
        last: state.last,
        currentCacheKey,
        currentNodeId,
      };

    case 'Frontend.Node.initialize':
      const node = new Entity(action.data.node, 3600);
      const page = new Entity(action.data.page, 3600);
      const data = new Entity(action.data.data, 3600);
      let nodeId = new Entity();
      if (action.data.node.nodeId ?? null) {
        nodeId = new Entity({ nodeId: action.data.node.nodeId }, 3600);
      }

      return {
        ...state,
        loading: !action.data.node,
        currentNodeId: action.data.node.nodeId,
        // @COFIXME[not-implemented](FLBML-146): previewId: action.data.route.parameters.preview,
        nodes: {
          [action.data.node.nodeId || action.data.route.parameters.preview]: node,
        },
        pages: {
          [action.data.node.nodeId || action.data.route.parameters.preview]: page,
        },
        nodeData: {
          [state.currentCacheKey]: data,
        },
        nodeIds: {
          [state.currentCacheKey]: nodeId,
        },
        last: {
          node: node,
          page: page,
          data: data,
        },
      };
  }

  return state;
};
