import React from 'react';
import type { AppProps } from 'next/app';
import { appWithTranslation } from 'next-i18next';
import { CatwalkProvider } from '@frontastic/catwalk/src/js/CatwalkProvider';
import Toaster from 'components/commercetools-ui/toaster';
import { FrontasticProvider } from 'frontastic';
import 'tailwindcss/tailwind.css';
import { getServerSideProps } from './[[...slug]]';
import deMessages from '../legacy/js/i18n/de';
import enMessages from '../legacy/js/i18n/en';

import './../legacy/js/patterns/atoms/image/style.scss';
import './../legacy/js/patterns/atoms/inputSearch/index.scss';
import './../legacy/js/patterns/atoms/tag/index.scss';
import './../legacy/js/patterns/atoms/upload/index.scss';
import './../legacy/js/patterns/molecules/Loaders/DefaultLoader/style.scss';
import './../legacy/js/patterns/molecules/Loaders/LoaderButton/index.scss';
import './../legacy/js/patterns/molecules/Loaders/LoaderButton/style.scss';
import './../legacy/js/patterns/molecules/Panels/bottomStyle.scss';
import './../legacy/js/patterns/molecules/Panels/leftStyle.scss';
import './../legacy/js/patterns/organisms/NewsletterBlock/input/index.scss';
import './../legacy/scss/app.scss';

const applyLegacyTranslatableFieldHack = (locale: string) => {
  if (!String.prototype.hasOwnProperty(locale)) {
    // @COFIXME[hack](FLBML-127): Verify if we ACTUALLY want to keep this.
    /**
     * This is one of the weirdest things I've ever done.
     *
     * Some legacy tastics "manually translate" strings.
     * If they do not use withTranslatedTasticData or tastify for translation and a tastic field is translatable,
     * tastics will receive a object like this:
     * ```
     * {
     *  de_DE: 'German string',
     *  en_GB: 'English string',
     * }
     *
     * In commercetools Frontend, translation is done in the API-Hub and the frontend code only receives strings.
     * In order to reverse this, we would need to have the tastic schema, but that's not available in the frontend.
     *
     * This hack creates a "magic" property getter for the current locale on the String prototype that returns the string itself.
     *
     * Example: If the locale is "de_DE", and you have a variable `const foo = 'bar'`, then `foo.de_DE` will return `'bar'`.
     */
    Object.defineProperty(String.prototype, locale, {
      get: function () {
        return this;
      },
    });
  }
};

type PageProps = Extract<
  Awaited<Extract<Awaited<ReturnType<typeof getServerSideProps>>, { props: unknown }>['props']>,
  { userAgent: string }
>;

function FrontasticStarter({ Component, pageProps, ...rest }: AppProps<PageProps>) {
  const { locale } = pageProps;

  // Provide legacy translation messages
  const messages = {
    en: enMessages,
    de: deMessages,
  };

  applyLegacyTranslatableFieldHack(locale);

  return (
    <>
      <FrontasticProvider>
        <CatwalkProvider pageProps={pageProps} {...rest} messages={messages}>
          <Component {...pageProps} />
          <Toaster />
        </CatwalkProvider>
      </FrontasticProvider>
    </>
  );
}

export default appWithTranslation(FrontasticStarter);
