import { UseAccount } from './UseAccount';
import { UseAdyen } from './UseAdyen';
import { UseCart } from './UseCart';
import { UseWishlist } from './UseWishlist';

export interface FrontasticState {
  useCart: UseCart;
  useAccount: UseAccount;
  useWishlist: UseWishlist;
  useAdyen: UseAdyen;
}

const defaultUseCart: UseCart = {
  addItem: (variant, quantity: number) => Promise.reject(),
  updateCart: (payload) => Promise.reject(),
  removeItem: (lineItemId: string) => Promise.reject(),
  updateItem: (lineItemId: string, newQuantity: number) => Promise.reject(),
  removeDiscountCode: (discount) => Promise.reject(),
  redeemDiscountCode: (code: string) => Promise.reject(),
  setShippingMethod: (shippingMethodId: string) => Promise.reject(),
  getShippingMethods: () => Promise.reject(),
  checkout: () => Promise.reject(),
};

const defaultUseAccount: UseAccount = {
  loggedIn: false,
  login: (email: string, password: string) => Promise.reject(),
  logout: () => Promise.reject(),
  register: (account) => Promise.reject(),
  confirm: (token: string) => Promise.reject(),
  requestConfirmationEmail: (email: string, password: string) => Promise.reject(),
  changePassword: (oldPassword: string, newPassword: string) => Promise.reject(),
  requestPasswordReset: (email: string) => Promise.reject(),
  resetPassword: (token: string, newPassword: string) => Promise.reject(),
  update: (account) => Promise.reject(),
  addAddress: (address) => Promise.reject(),
  updateAddress: (address) => Promise.reject(),
  removeAddress: (addressId: string) => Promise.reject(),
  setDefaultBillingAddress: (addressId: string) => Promise.reject(),
  setDefaultShippingAddress: (addressId: string) => Promise.reject(),
};

const defaultUseWishlist: UseWishlist = {
  addToWishlist: (sku: string, count?: number) => Promise.reject(),
  removeLineItem: (lineItemId: string) => Promise.reject(),
  updateLineItem: (lineItemId: string, count?: number) => Promise.reject(),
};

const defaultUseAdyen: UseAdyen = {
  createSession: (value: number, currency: string, returnUrl: any) => Promise.reject(),
};

export const getFrontasticState = (): FrontasticState => {
  return {
    useCart: defaultUseCart,
    useAccount: defaultUseAccount,
    useWishlist: defaultUseWishlist,
    useAdyen: defaultUseAdyen,
  };
};
