import { ProductListAction } from '../actions/ProductListActions'

export type ProductListState = {
    objectId: string | null
    openFilter: string | null
    query: string | null
    showMore: boolean
    nameCategory: string
    breadcrumb: object[]
    pathname: string | null
    categoryUrl: string | null
    categorySeoTitle: string | null
    categorySeoDescription: string | null
}

export const initialGlobalState: ProductListState = {
    objectId: null,
    openFilter: null,
    query: null,
    showMore: false,
    nameCategory: '',
    breadcrumb: [],
    pathname: null,
    categoryUrl: null,
    categorySeoTitle: null,
    categorySeoDescription: null,
}

export default (
    globalState = initialGlobalState,
    action: ProductListAction,
) => {
    switch (action.type) {
        case 'ACTION_EDIT_PATH_NAME':
            if (!action.data) {
                return globalState
            }

            return {
                ...globalState,
                pathname: action.data.pathname,
            }

        case 'ACTION_EDIT_QUERY':
            if (!action.data) {
                return globalState
            }

            return {
                ...globalState,
                query: action.data.query,
            }
        case 'ACTION_OPEN_FILTER':
            if (!action.data) {
                return globalState
            }

            return {
                ...globalState,
                openFilter: action.data.openFilter,
            }
        case 'ACTION_SHOW_MORE':
            if (!action.data) {
                return globalState
            }

            return {
                ...globalState,
                showMore: action.data.showMore,
            }
        case 'ACTION_CATEGORY_NAME':
            if (!action.data) {
                return globalState
            }

            return {
                ...globalState,
                nameCategory: action.data.nameCategory,
            }
        case 'ACTION_BREADCRUMB':
            if (!action.data) {
                return globalState
            }

            return {
                ...globalState,
                breadcrumb: action.data.breadcrumb,
            }
        case 'ACTION_UPDATE_CATEGORY_URL':
            if (!action.data) {
                return globalState
            }

            return {
                ...globalState,
                categoryUrl: action.data.categoryUrl,
            }
        case 'ACTION_UPDATE_CATEGORY_SEO':
            if (!action.data) {
                return globalState
            }

            return {
                ...globalState,
                categorySeoTitle: action.data.categorySeoTitle,
                categorySeoDescription: action.data.categorySeoDescription,
            }
        default:
            return globalState
    }
}
