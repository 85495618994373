import { useEffect, useState } from 'react';
import ComponentInjector from '@frontastic/catwalk/src/js/app/injector';
import debounce from 'lodash.debounce';
import { useLocation } from 'react-router-dom';
import useVirtualPageView from '../services/hook/useVirtualPageView';

/**
 * This component is wrapped around most of the application,
 * and can be overwritten using the component injector.
 */
const AppContainer = ({ children }) => {
  const location = useLocation();

  const setCustomProperties = () => {
    const viewportHeight = window.innerHeight * 0.01;
    const scrollbarWidth = window.innerWidth - document.body.clientWidth;

    document.documentElement.style.setProperty('--scrollbar-width', `${scrollbarWidth}px`);
    document.documentElement.style.setProperty('--viewport-height', `${viewportHeight}px`);
  };

  const scrollToElement = debounce(() => {
    const hash = window.location.hash.substring(1);

    if (!hash) {
      return;
    }

    const element = document.getElementById(hash);
    if (element) {
      const y = element.getBoundingClientRect().top + window.pageYOffset;
      window.scrollTo({ top: y, behavior: 'smooth' });
    }
  }, 700);

  useEffect(() => {
    setCustomProperties();
    scrollToElement();

    window.addEventListener(
      'resize',
      debounce(() => setCustomProperties(), 50),
      { passive: true },
    );

    return () => {
      window.removeEventListener(
        'resize',
        debounce(() => setCustomProperties(), 50),
      );
      scrollToElement.cancel();
    };
  }, []);

  const [documentTitle, setDocumentTitle] = useState(typeof window !== 'undefined' ? window.document.title : '');
  const { setDependencies } = useVirtualPageView(documentTitle);

  useEffect(() => {
    const intervalId = setInterval(() => {
      const currentTitle = window.document.title;

      if (currentTitle !== documentTitle) {
        setDependencies(currentTitle);
        setDocumentTitle(currentTitle);
        clearInterval(intervalId);
      }
    }, 500);

    const timeoutId = setTimeout(() => {
      clearInterval(intervalId);
    }, 5000);

    return () => {
      clearInterval(intervalId);
      clearTimeout(timeoutId);
    };
  }, [location.asPath]);

  return children;
};

export default ComponentInjector.return('AppContainer', AppContainer);
