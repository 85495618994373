const initialGlobalState = {
    isOpen: false,
    newAddedProduct: null,
}
const miniCart = (globalState = initialGlobalState, action) => {
    switch (action.type) {
        case 'ACTION_CLOSE_MINI_CART':
            return {
                ...globalState,
                isOpen: false,
            }
        case 'ACTION_OPEN_MINI_CART':
            return {
                ...globalState,
                isOpen: true,
            }
        case 'ACTION_SAVE_ADDED_NEW_PRODUCT':
            return {
                ...globalState,
                newAddedProduct: action.payload,
            }
        default:
        // Do nothing for other actions
    }

    return globalState
}
export default miniCart
