class GtmService {
    constructor(products = [], product = {}, position = 1, streamData = {}) {
        this.product = product
        this.products = products
        this.position = position
        this.streamData = streamData
    }

    getAttributes(item) {
        if (item.variants && item.variants.length) {
            return item.variants[0].attributes
        }

        if (item.attributes) {
            return item.attributes
        }

        return item.variant.attributes
    }

    getSum() {
        return this.streamData.sum / 100
    }

    getTaxed() {
        return this.streamData.taxed && this.streamData.taxed.taxPortions && this.streamData.taxed.taxPortions.length && this.streamData.taxed.taxPortions[0].amount / 100
    }

    getOrderId() {
        return this.streamData.orderId
    }

    getCoupon() {
        return this.streamData.discountCodes && this.streamData.discountCodes.length > 0 ? this.streamData.discountCodes[0] : ''
    }

    getShipping() {
        return this.streamData.shippingInfo.price
    }

    getActiveColor(item) {
        return item.colors.filter((color) => color.isActive)
    }

    getPrice(item) {
        if (item?.price) {
            return item.price / 100
        }

        const priceVariants = item.variants || item.price_variants
        const priceList = new Set(priceVariants.map((variant) => variant.discountedPrice || variant.price))

        return Math.min(...priceList) / 100
    }

    getBrand(item) {
        return this.getAttributes(item).att_prod_brand
    }

    getColor(item) {
        return this.getAttributes(item).att_prod_color_name_sport2000
    }

    getName(item) {
        return this.getAttributes(item).att_prod_product_name || item.name
    }

    getCategory(item) {
        return this.getAttributes(item).att_internal_category_online_shop
    }

    getVariant(item) {
        if (item.variants && item.variants.length > 0) {
            return item.variants[0]
        }

        return item.variant
    }

    getProductId(item) {
        return item?.sku || this.getVariant(item)?.sku
    }

    createVirtualPageView(pageUrl, pageTitle) {
        return {
            event: 'virtualPageview',
            pageUrl,
            pageTitle,
        }
    }

    createProductListGTM(categoryName, categoryId, userToken) {
        const productListGTM = []
        let productGTM = {}
        this.products.forEach((item, index) => {
            const queryId = item.projectSpecificData?.queryId
            productGTM = {
                item_name: this.getName(item),
                item_id: this.getProductId(item),
                currency: 'EUR',
                index,
                item_brand: this.getBrand(item),
                item_category: this.getCategory(item),
                item_list_id: categoryId,
                item_list_name: categoryName,
                item_variant: this.getColor(item),
                price: this.getPrice(item),
                quantity: item.count || 1,
            }

            if (queryId) {
                productGTM.query_id = queryId
            }

            if (userToken) {
                productGTM.user_token = userToken
            }

            productListGTM.push(productGTM)
        })
        return productListGTM
    }

    createProductGTM(pageName = '', addProps, removeProps) {
        let productGTM = {
            item_id: this.getProductId(this.product),
            item_name: this.getName(this.product),
            currency: 'EUR',
            index: 0,
            item_brand: this.getBrand(this.product),
            item_category: this.getCategory(this.product),
            item_list_id: pageName.toLowerCase().replace(' ', '_'),
            item_list_name: pageName,
            item_variant: this.getColor(this.product),
            price: this.getPrice(this.product),
            quantity: 1,
            query_id: this.product.queryId,
        }

        if (addProps) {
            productGTM = {
                ...productGTM,
                ...addProps,
            }
        }

        if (removeProps && removeProps.length) {
            removeProps.forEach((prop) => {
                delete productGTM[prop]
            })
        }

        return productGTM
    }

    createPurchaseActionFieldGTM() {
        return {
            transaction_id: this.getOrderId(),
            value: this.getSum(),
            tax: this.getTaxed(),
            shipping: this.getShipping(),
            currency: 'EUR',
            coupon: this.getCoupon(),
        }
    }

    createShippingInfoGTM() {
        return {
            currency: 'EUR',
            value: this.getSum(),
            coupon: this.getCoupon(),
        }
    }

    createPaymentInfoGTM(paymentName) {
        return {
            currency: 'EUR',
            value: this.getSum(),
            coupon: this.getCoupon(),
            payment_type: paymentName,
        }
    }

    createBeginCheckoutInfoGTM() {
        return {
            currency: 'EUR',
            value: this.getSum(),
            coupon: this.getCoupon(),
        }
    }
}

export default GtmService
