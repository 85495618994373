import { ThemeAction } from '../actions/ThemeActions'

export type ThemeState = {
    themeName: string
}

export const initialGlobalState: ThemeState = {
    themeName: '',
}

export default (
    globalState = initialGlobalState,
    action: ThemeAction,
) => {
    switch (action.type) {
        case 'ACTION_SET_THEME':
            if (!action.data) {
                return globalState
            }

            return {
                ...globalState,
                themeName: action.data.themeName,
            }
        default:
            return globalState
    }
}
