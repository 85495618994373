import debounce from 'lodash.debounce'

const TIME_INTERVAL_REQUEST_PUSH_USER_TOKEN = 500
const TIME_DEBOUNCE_PUSH_USER_TOKEN = 2000

const debounceOnPushUserToken = debounce(() => {
    window.aa && window.aa('onUserTokenChange', (userToken) => {
        debounceOnPushUserToken.cancel()
        window.localStorage.setItem('userToken', userToken)
        window.dataLayer.push({
            algoliaUserToken: userToken,
        })
    }, { immediate: true })
}, TIME_DEBOUNCE_PUSH_USER_TOKEN)

const pushUserToken = () => {
    const intervalPushUserToken = setInterval(() => {
        if (!window.aa) {
            return
        }

        clearInterval(intervalPushUserToken)
        debounceOnPushUserToken()
    }, TIME_INTERVAL_REQUEST_PUSH_USER_TOKEN)
}

export default pushUserToken
