const initialGlobalState = {
    isRequireTopicChecked: true,
    isSubscibeChecked: false,
    topics: null,
    invalidTopic: '',
}
const subscribeEmail = (globalState = initialGlobalState, action) => {
    switch (action.type) {
        case 'ACTION_CHECK_REQUIRE_TOPIC':
            return {
                ...globalState,
                isRequireTopicChecked: action.payload,
            }
        case 'ACTION_TOGGLE_SUBSCRIBE_CHECKBOX':
            return {
                ...globalState,
                isSubscibeChecked: action.payload,
            }
        case 'ACTION_UPDATE_TOPICS':
            return {
                ...globalState,
                topics: action.payload,
            }
        case 'ACTION_UPDATE_INVALID_TOPIC':
            return {
                ...globalState,
                invalidTopic: action.payload,
            }
        default:
        // Do nothing for other actions
    }

    return globalState
}
export default subscribeEmail
