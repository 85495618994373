import { v4 } from 'uuid';
import origDeprecate from '@frontastic/common/src/js/helper/deprecate';
export { default as MediaApi } from '@frontastic/common/src/js/mediaApi';

export const deprecate = origDeprecate;
export const generateId = v4;

export class Tastic {
  constructor(data) {
    for (const key in data) {
      this[key] = data[key];
    }
  }
}

export { default as ConfigurationSchema } from './src/js/configuration/schema';
