/**
 * Frontastic Dependency Injection for JavaScript Stack
 *
 * Use this file to overwrite any dependency in Frontastic which is injected
 * using the ComponentInjector.
 */

import {object} from 'yup';

/* eslint-disable no-unused-vars */
import ComponentInjector from '@frontastic/catwalk/src/js/app/injector'
import AppContainer from './app/AppContainer'
// @COFIXME[not-implemented](FLBML-147): import intlProvider from './i18n/intlProvider'
import './store/reducers'
// @COFIXME[not-implemented](FLBML-147): import NodeConfigurationSchema from './config/defaultSchemas/nodeConfiguration'
import AtomsNotification from './patterns/molecules/Notifications/Default'
/* eslint-enable no-unused-vars */

ComponentInjector.set('AppContainer', AppContainer)
// @COFIXME[not-implemented](FLBML-147): ComponentInjector.set('App.IntlProvider', intlProvider)
// @COFIXME[not-implemented](FLBML-147): ComponentInjector.set('NodeConfigurationSchema', NodeConfigurationSchema)
// @COFIXME[not-implemented](FLBML-147): ComponentInjector.set('ScrollbarContainer', ({ children }) => children)
ComponentInjector.set('AtomsNotification', AtomsNotification)

// This is just a dummy function that can be called from the client code so the import won't be optimized away.
// It would be nice to manipulate the ComponentInjector within this function
// but the function call is invoked to late and therefore the components won't be overridden on time.
export default function injection() {
}
