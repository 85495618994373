import app from '@frontastic/catwalk/js/app/app';
import Context from '@frontastic/catwalk/js/app/context';
import FrontasticRoute from '@frontastic/catwalk/js/app/route';
import { NextRouter } from 'next/router';
import { useContext, useEffect, useRef } from 'react';
import { ReactReduxContext } from 'react-redux';
import { PageDataResponse } from '../../frontastic';
import debounce from '../common/src/js/helper/debounce';
import { trackingPageView } from './reducer/node';
import { store } from './store';

/*
 * The callback is only executed once during the initial rendering and never again.
 * In contrast to useEffect, this hook calls the callback during rendering and not afterward.
 */
const useOnlyExecuteOnInitialRender = (callback: () => void) => {
  const isInitialRenderRef = useRef(true);

  if (!isInitialRenderRef.current) {
    return;
  }

  callback();
  isInitialRenderRef.current = false;
};

function useFrontasticBridgeReduxDispatches(
  router: NextRouter,
  pageData: PageDataResponse,
  locale: string,
  userAgent: string,
  contextData: any | null,
) {
  const reduxContext = useContext(ReactReduxContext);

  useOnlyExecuteOnInitialRender(() => {
    const isServerSide = !!reduxContext;

    store.dispatch({
      type: 'FRONTASTIC_BRIDGE_INIT',
    });

    if (isServerSide) {
      store.dispatch({
        type: 'FRONTASTIC_BRIDGE_SERVER',
      });
    } else {
      store.dispatch({
        type: 'FRONTASTIC_BRIDGE_CLIENT',
      });
    }

    app.initialize(router, new Context(contextData));

    // @COFIXME[error-handling](FLBML-135): Properly handle non page results (I got an error here on a 500 in the page request)
    store.dispatch({
      type: 'FRONTASTIC_ROUTE',
      route: new FrontasticRoute(app.getRouter().match(router.asPath.split('?')[0]), router.query, null),
      lastRoute: null,
    });

    if (pageData?.pageFolder?.pageFolderId) {
      store.dispatch({
        type: 'Frontend.Node.initialize',
        data: {
          node: { ...pageData.pageFolder, nodeId: pageData.pageFolder.pageFolderId },
          page: pageData.page,
          data: pageData.data,
          route: {
            // @COFIXME[routing](FLBML-125): No idea where the route data should come from, but not from pageData :D
            // ...pageData.route,
            parameters: {},
          },
        },
      });
    }

    store.dispatch({
      type: 'ApiBundle.Api.context.success',
      data: {
        ...contextData,
        locale: locale,
      },
    });

    store.dispatch({
      type: 'Frontastic.RenderContext.UserAgentDetected',
      userAgent: userAgent,
    });
  });

  useEffect(() => {
    // @COFIXME[error-handling](FLBML-136): Properly handle non page results (404s and maybe redirects)
    if (!pageData?.pageFolder?.pageFolderId) {
      return;
    }

    store.dispatch(
      trackingPageView({
        nodeId: pageData.pageFolder.pageFolderId,
        data: {
          node: pageData.pageFolder,
          page: pageData.page,
          data: pageData.data,
        },
        // @COFIXME(FLBML-139): Determine if this is correct
        isMasterPage: pageData.pageFolder.isDynamic,
      }),
    );
    // The following is a workaround to execute this only if the pageFolderId changes.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageData?.pageFolder?.pageFolderId]);

  useEffect(() => {
    // After the initial render, we want to dispatch the viewport dimensions
    // Before this, we **should** use the user agent from SSR
    const dispatchViewportDimensions = () => {
      store.dispatch({
        type: 'Frontastic.RenderContext.ViewportDimensionChanged',
        viewportDimension: {
          width: window.innerWidth,
          height: window.innerHeight,
        },
      });
    };

    dispatchViewportDimensions();
    window.addEventListener('resize', debounce(dispatchViewportDimensions, 500));

    // After the initial render, we dispatch that this is a client side render
    // Before the initial render it needs to run in SSR mode, otherwise we'd have a hydration diff.
    store.dispatch({
      type: 'Frontastic.RenderContext.ClientSideDetected',
    });
  }, []);
}

export default useFrontasticBridgeReduxDispatches;
