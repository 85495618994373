import * as React from 'react';
import { SWRConfig } from 'swr';
import { fetchApiHub } from '../../lib/fetch-api-hub';
import DarkModeProvider from '../dark-mode';
import { FrontasticState, getFrontasticState } from './FrontasticState';
import { UseAccount } from './UseAccount';
import { UseAdyen } from './UseAdyen';
import { UseCart } from './UseCart';
import { UseWishlist } from './UseWishlist';

const initialState = getFrontasticState();

export const FrontasticContext = React.createContext<FrontasticState>(initialState);

export const FrontasticProvider: React.FC = ({ children }) => {
  const state: FrontasticState = getFrontasticState();
  return (
    <SWRConfig value={{ fetcher: fetchApiHub }}>
      <DarkModeProvider>
        <FrontasticContext.Provider value={state}>{children}</FrontasticContext.Provider>
      </DarkModeProvider>
    </SWRConfig>
  );
};

const checkContext = (context: FrontasticState) => {
  if (!context) {
    throw new Error('Expected to be wrapped in FrontasticProvider');
  }
};

export const useCart = (): UseCart => {
  const context = React.useContext(FrontasticContext);
  checkContext(context);

  return context.useCart;
};

export const useAccount = (): UseAccount => {
  const context = React.useContext(FrontasticContext);
  checkContext(context);

  return context.useAccount;
};

export const useWishlist = (): UseWishlist => {
  const context = React.useContext(FrontasticContext);
  checkContext(context);

  return context.useWishlist;
};

export const useAdyen = (): UseAdyen => {
  const context = React.useContext(FrontasticContext);
  checkContext(context);

  return context.useAdyen;
};
