import { Reducer } from 'redux';

class ComponentInjector {
  static readonly registry: Record<string, unknown> = {};
  static readonly reducer: Record<string, Reducer> = {};

  static registerReducer(name: string, reducer: Reducer) {
    if (!name.match(/^[a-z]+-/)) {
      throw new Error('The reducer should be prefixed with your customer name, like "demo-reducer".');
    }

    this.reducer[name] = reducer;
  }

  static get(name) {
    return this.registry[name] || null;
  }

  static set(name, component) {
    this.registry[name] = component;
  }

  static return(name, component) {
    return this.registry[name] || component;
  }
}

export default ComponentInjector;
