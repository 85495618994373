// store.ts

import { devToolsEnhancer } from '@redux-devtools/extension';
import { TypedUseSelectorHook, useSelector } from 'react-redux';
import { combineReducers, createStore } from 'redux';
import ComponentInjector from '@frontastic/catwalk/src/js/app/injector';
import { appReducer } from './reducer/app';
import { frontasticBridgeReducer } from './reducer/frontasticBridge';
import { nodeReducer } from './reducer/node';
import renderContextReducer from './reducer/renderContext';
import CartLoader from '../catwalk/js/app/loader/cart';
import ContextLoader from '../catwalk/js/app/loader/context';
import WishlistLoader from '../catwalk/js/app/loader/wishlist';

const frontasticReducers = {
  node: nodeReducer,
  app: appReducer,
  cart: CartLoader.handleAction,
  user: ContextLoader.handleAction,
  wishlist: WishlistLoader.handleAction,
  frontasticBridge: frontasticBridgeReducer,
  renderContext: renderContextReducer,
};

const frontasticReducer = combineReducers(frontasticReducers);

const dynamicReducer: typeof frontasticReducer = (state, action) => {
  if (Object.keys(ComponentInjector.reducer).length === 0) {
    return frontasticReducer(state, action);
  }

  const injectedReducer = combineReducers(ComponentInjector.reducer);

  return {
    ...injectedReducer(
      state === undefined
        ? undefined
        : Object.fromEntries(Object.entries(state).filter(([key]) => Object.hasOwn(ComponentInjector.reducer, key))),
      action,
    ),
    ...frontasticReducer(
      state === undefined
        ? undefined
        : (Object.fromEntries(
            Object.entries(state).filter(([key]) => Object.hasOwn(frontasticReducers, key)),
          ) as typeof state),
      action,
    ),
  };
};

export const store = createStore(dynamicReducer, devToolsEnhancer());

export type FrontasticStore = typeof store;
export type FrontasticState = ReturnType<FrontasticStore['getState']>;

export const useFrontasticSelector: TypedUseSelectorHook<FrontasticState> = useSelector;
