import Api from './api';
import CartLoader from './loader/cart';
import ContextLoader from './loader/context';
import WishlistLoader from './loader/wishlist';

class Loader {
  readonly api: Api | undefined = undefined;
  readonly loaders: Record<string, any> = {};

  constructor(store, router, api) {
    this.api = api;
    this.loaders = {
      cart: new CartLoader(store, api),
      context: new ContextLoader(store, api),
      wishlist: new WishlistLoader(store, api),
    };
  }

  getLoader(name) {
    if (!(name in this.loaders)) {
      throw new Error('Unknown loader ' + name);
    }

    return this.loaders[name];
  }

  loadContentForPath(pathname, query = {}, historyState = null) {
    this.api.clearContinuousRequests();

    this.loaders.cart.getContinuously();
    this.loaders.wishlist.getContinuously();
  }
}

export default Loader;
