import * as React from 'react';
import { FormattedMessage } from 'react-intl';

interface Props {
  code?: string;
  message: string;
  parameters?: Record<string, React.ReactNode>;
}

const Message: React.FunctionComponent<Props> = ({ code = 'Unknown', message, parameters = {} }) => {
  if (!code) {
    console.warn(`FormattedMessage is missing an id, and will thus not be translated: ${message}`);
    return <>message</>;
  }

  return <FormattedMessage id={code} defaultMessage={message} values={parameters} />;
};

export default Message;
