/**
 * This is the base class for data fetched from the server.
 *
 * This class provides a isCurrent() method based on an entity specific
 * time-to-live (TTL) to check if the entity should be refetched.
 */
class Entity<T = any> {
  loading: boolean;
  loaded: Date | null;
  error: any;
  data: T | null;
  ttl: number;

  static EMPTY: Entity = new Entity();

  constructor(data?: T | null, ttl?: number) {
    this.loading = !data;
    this.loaded = data ? new Date() : null;
    this.error = null;
    this.data = data || null;
    this.ttl = ttl || 60;
  }

  isComplete() {
    return !(this.loading || this.error);
  }

  hasData() {
    return !!this.data;
  }

  isCurrent() {
    if (!this.loaded || this.error) {
      return false;
    }

    if (this.loaded.getTime() > new Date().getTime() - this.ttl * 1000) {
      return true;
    }

    return false;
  }

  setError(error: any) {
    this.loading = false;
    this.loaded = null;
    this.error = error;
    this.data = null;

    return this;
  }

  setLoading() {
    this.loading = true;

    return this;
  }

  dataWhenComplete(defaultValue = null) {
    if (this.isComplete()) {
      return this.data;
    }
    return defaultValue;
  }

  static purgeMap(map: Record<string, any>) {
    const newMap: Record<string, any> = {};
    for (const key in map) {
      if (map[key] && map[key].isCurrent && map[key].isCurrent()) {
        newMap[key] = map[key];
      }
    }

    return newMap;
  }

  static purge(entity: Entity) {
    if (entity && entity.isCurrent()) {
      return entity;
    }

    return Entity.EMPTY;
  }

  static orEmpty(entity: Entity) {
    return entity || Entity.EMPTY;
  }

  static withData(data: any, ttl = 60) {
    const entity = new Entity(data, ttl);
    entity.loading = false;
    entity.loaded = new Date();
    return entity;
  }
}

export default Entity;
