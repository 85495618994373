import React, { useEffect } from 'react'
import { Slide, toast, ToastContainer } from 'react-toastify'
import ComponentInjector from '@frontastic/catwalk/src/js/app/injector'

import 'react-toastify/dist/ReactToastify.css'
import { CheckCircleIcon } from '../../atoms/icon'

interface Props {
    message: string
    notificationId: string
    onClose: () => void
    type: 'success' | 'info' | 'warning' | 'error',
    autoHideDuration: number
}

function Notification({
    message, type, notificationId, onClose, autoHideDuration
}: Props) {
    const AlertError = () => (
        <div className={'alert alert--error'}>
            <CheckCircleIcon width={24} height={24} />
            <p>{message}</p>
        </div>
    )

    const AlertSuccess = () => (
        <div className={'alert alert--success'}>
            <CheckCircleIcon width={24} height={24} />
            <p>{message}</p>
        </div>
    )

    const alert = type === 'error' ? AlertError() : AlertSuccess()
    useEffect(() => {
        if (!toast.isActive(notificationId)) {
            toast(alert, {
                className: 'notification-default',
                toastId: notificationId,
                onClose: () => {
                    onClose()
                },
            })
        }
    }, [])

    return (
        <ToastContainer
            position={toast.POSITION.TOP_RIGHT}
            autoClose={3300}
            closeButton
            hideProgressBar
            newestOnTop={false}
            rtl={false}
            transition={Slide}
            pauseOnFocusLoss
            pauseOnHover
            draggable
            closeOnClick
            className={'alert--wrapper'}
        />
    )
}

export default ComponentInjector.return('AtomsNotification', Notification)
