import { useEffect, useState } from 'react';
import useConsentManagement from './useConsentManagement';
import OldTagManager from '../../domain/OldTagManager';
import GtmService from '../../services/gtm';

export default function useVirtualPageView(initListener) {
  const [dependencies, setDependencies] = useState(initListener);
  const { isGtmAccepted } = useConsentManagement();

  useEffect(() => {
    if (isGtmAccepted && !!dependencies && (!!window.location.href || !!document.title)) {
      const virtualPageViewData = new GtmService().createVirtualPageView(window.location.href, document.title);

      OldTagManager.push(virtualPageViewData);
    }
  }, [dependencies, isGtmAccepted]);

  return { setDependencies };
}
