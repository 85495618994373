import { AnyAction } from 'redux';

type AppReducerState = {
  context: {
    locale: string;
    routes: {
      [key: string]: {
        path: string;
        _locale: unknown | null;
        _frontastic_canonical_route: unknown | null;
        requirements: unknown[];
      };
    };
  };
};

const initialState: AppReducerState = {
  context: {
    locale: 'de_DE',
    routes: {},
  },
};

export const appReducer = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case 'FRONTASTIC_ROUTE':
      return {
        ...state,
        route: action.route,
      };
    case 'ApiBundle.Api.context.success':
      return {
        ...state,
        context: action.data,
      };
  }

  return state;
};
