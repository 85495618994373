import ComponentInjector from '@frontastic/catwalk/src/js/app/injector'
import MiniCartReducer from './MiniCartReducer'
import ProductListAlgoliaReducer from './ProductListReducer'
import StoreReducer from './StoreReducer'
import ThemeReducer from './ThemeReducer'
import SubscribeEmailReducer from './SubscribeEmailReducer'

ComponentInjector.registerReducer('mini-cart', MiniCartReducer)
ComponentInjector.registerReducer('product-list-algolia', ProductListAlgoliaReducer)
ComponentInjector.registerReducer('select-store', StoreReducer)
ComponentInjector.registerReducer('theme-status', ThemeReducer)
ComponentInjector.registerReducer('subscribe-email', SubscribeEmailReducer)
