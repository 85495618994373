import React, { lazy, Suspense } from 'react'
import PropTypes from 'prop-types'

// Create a map to cache the dynamically loaded icons
const iconCache = {}

const loadIcon = (iconName, onError, onCompleted) => {
    if (!iconCache[iconName]) {
        iconCache[iconName] = lazy(() =>
          import(`../../../../icons/sport2000-icons/${iconName}.svg`)
          .then((module) => {
              onCompleted && onCompleted(iconName, module.ReactComponent)
              return { default: module.ReactComponent }
          })
          .catch((error) => {
              console.error(`Failed to load icon ${iconName}:`, error)
              onError && onError(error)
              return { default: () => null }
          })
        )
    }
    return iconCache[iconName]
}

const Icon = ({ iconName, onError, onCompleted, ...rest }) => {
    const LazyIcon = loadIcon(iconName, onError, onCompleted)

    return (
      <Suspense fallback={<div className="h-4 w-4 rounded-full bg-blacksport-10" />}>
          <LazyIcon {...rest} />
      </Suspense>
    )
}

const IconComponent = ({ name, onClick, width, height, className, ...rest }) => {
    const onClickIcon = () => {
        if (onClick) {
            onClick()
        }
    }

    return (
      <span onClick={onClickIcon} className={className}>
          <Icon
            iconName={name}
            width={width}
            height={height}
            {...rest}
          />
      </span>
    )
}

IconComponent.propTypes = {
    name: PropTypes.string,
    onClick: PropTypes.func,
    onError: PropTypes.func,
    onCompleted: PropTypes.func,
    width: PropTypes.number,
    height: PropTypes.number,
    className: PropTypes.string,
}

export const ArrowDownIcon = (props) => (
    <IconComponent name={'arrow-down'} {...props} />
)

export const ArrowThickDownIcon = (props) => (
    <IconComponent name={'arrow-thick-down'} {...props} />
)

export const ArrowThinLeftIcon = (props) => (
    <IconComponent name={'arrow-l'} {...props} />
)

export const ArrowThickLeftIcon = (props) => (
    <IconComponent name={'arrow-thick-left'} {...props} />
)

export const ArrowThickRightIcon = (props) => (
    <IconComponent name={'arrow-thick-right'} {...props} />
)

export const ArrowLeftIcon = (props) => (
    <IconComponent name={'arrow-left'} {...props} />
)

export const ArrowLIcon = (props) => (
    <IconComponent name={'arrowleft'} {...props} />
)

export const ArrowRightIcon = (props) => (
    <IconComponent name={'arrow-right'} {...props} />
)

export const ArrowRIcon = (props) => (
    <IconComponent name={'arrow-r'} {...props} />
)

export const NarrowLeftIcon = (props) => (
    <IconComponent name={'arrowleft'} {...props} />
)

export const ArrowUpIcon = (props) => (
    <IconComponent name={'arrow-up'} {...props} />
)

export const CartIcon = (props) => (
    <IconComponent name={'cart'} {...props} />
)

export const CheckIcon = (props) => (
    <IconComponent name={'check'} {...props} />
)

export const CloseIcon = (props) => (
    <IconComponent name={'close'} {...props} />
)

export const CompareIcon = (props) => (
    <IconComponent name={'compare'} {...props} />
)

export const FileIcon = (props) => (
    <IconComponent name={'file'} {...props} />
)

export const HeartIcon = (props) => (
    <IconComponent name={'heart'} {...props} />
)

export const LocationIcon = (props) => (
    <IconComponent name={'location'} {...props} />
)

export const MenuIcon = (props) => (
    <IconComponent name={'menu'} {...props} />
)

export const ScaleIcon = (props) => (
    <IconComponent name={'scale'} {...props} />
)

export const SearchIcon = (props) => (
    <IconComponent name={'search'} {...props} />
)

export const StarFullIcon = (props) => (
    <IconComponent name={'star-rating-filled'} {...props} />
)

export const StarNotFullIcon = (props) => (
    <IconComponent name={'star-rating-outline'} {...props} />
)

export const StarIcon = (props) => (
    <IconComponent name={'star'} {...props} />
)

export const ThumbsUpIcon = (props) => (
    <IconComponent name={'thumbsup'} {...props} />
)

export const UpLoadIcon = (props) => (
    <IconComponent name={'upload'} {...props} />
)

export const UserIcon = (props) => (
    <IconComponent name={'user'} {...props} />
)

export const WorldIcon = (props) => (
    <IconComponent name={'world'} {...props} />
)

export const HeartFillIcon = (props) => (
    <IconComponent name={'heart-filled'} {...props} />
)

export const TruckIcon = (props) => (
    <IconComponent name={'truck'} {...props} />
)

export const VisaIcon = (props) => (
    <IconComponent name={'visa'} {...props} />
)

export const PayPalIcon = (props) => (
    <IconComponent name={'paypal'} {...props} />
)

export const MasterCardIcon = (props) => (
    <IconComponent name={'mastercard'} {...props} />
)

export const WorldInWhiteIcon = (props) => (
    <IconComponent name={'world-in-white'} {...props} />
)

export const OutDoorIcon = (props) => (
    <IconComponent name={'outdoor'} {...props} />
)

export const WaterProofIcon = (props) => (
    <IconComponent name={'waterproof'} {...props} />
)

export const ComfortIcon = (props) => (
    <IconComponent name={'comfort'} {...props} />
)

export const GripIcon = (props) => (
    <IconComponent name={'grip'} {...props} />
)

export const VeganIcon = (props) => (
    <IconComponent name={'vegan'} {...props} />
)

export const FilterIcon = (props) => (
    <IconComponent name={'filter'} {...props} />
)

export const LoadingIcon = (props) => (
    <IconComponent name={'loading'} {...props} />
)

export const DeleteIcon = (props) => (
    <IconComponent name={'delete'} {...props} />
)

export const AddIcon = (props) => (
    <IconComponent name={'add-circle'} {...props} />
)

export const ClockIcon = (props) => (
    <IconComponent name={'clock'} {...props} />
)

export const EditIcon = (props) => (
    <IconComponent name={'edit'} {...props} />
)

export const CheckCircleIcon = (props) => (
    <IconComponent name={'check-circle'} {...props} />
)

export const LogOutIcon = (props) => (
    <IconComponent name={'logout'} {...props} />
)

export const ArrowLWhiteIcon = (props) => (
    <IconComponent name={'arrowleft_white'} {...props} />
)

export const LabelIcon = (props) => (
    <IconComponent name={'label'} {...props} />
)

export const RouteIcon = (props) => (
    <IconComponent name={'route'} {...props} />
)

export const PhoneIcon = (props) => (
    <IconComponent name={'phone'} {...props} />
)

export const InfoIcon = (props) => (
    <IconComponent name={'info'} {...props} />
)

export const EmailIcon = (props) => (
    <IconComponent name={'envelope'} {...props} />
)
