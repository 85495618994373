import UserToken from './UserToken'

class OldTagManager {
    constructor(dataLayerIdentifier = 'dataLayer') {
        this.dataLayerIdentifier = dataLayerIdentifier
    }

    hitViewdImpressions = () => {
        this.push({ event: 'Hits Viewed' })
        UserToken()
    }

    productImpressions = (productList) => {
        this.push({
            event: 'productList',
            ecommerce: {
                impressions: productList,
            },
        })
    }

    productClick = (product, nameCategory = 'Search Result') => {
        this.push({
            event: 'productClick',
            ecommerce: {
                click: {
                    actionField: { list: `Page: ${nameCategory}` },
                    products: [product],
                },
            },
        })
    }

    productDetailImpressions = (product) => {
        this.push({
            ecommerce: {
                detail: {
                    actionField: { list: 'Product Detail' },
                    products: [product],
                },
            },
        })
    }

    addProduct = (product) => {
        this.push({
            event: 'addToCart',
            ecommerce: {
                currencyCode: 'EUR',
                add: {
                    products: [product],
                },
            },
        })
    }

    removeProduct = (product) => {
        this.push({
            event: 'removeFromCart',
            ecommerce: {
                remove: {
                    products: [product],
                },
            },
        })
    }

    purchase = (products, actionField) => {
        this.push({
            ecommerce: {
                purchase: {
                    actionField,
                    products,
                },
            },
        })
    }

    order = (orderInfo) => {
        this.push({
            event: 'conversion',
            ...orderInfo,
        })
    }

    push = (data) => {
        const dataLayer = this.getDataLayer()
        if (!dataLayer) {
            return
        }

        dataLayer.push(data)
    }

    getDataLayer = () => {
        if (!window || !window[this.dataLayerIdentifier]) {
            // console.warn('Data layer with identifier "' + this.dataLayerIdentifier + '" could not be accessed')
            return null
        }

        return window[this.dataLayerIdentifier]
    }
}

export default new OldTagManager()
