import { AnyAction } from 'redux';

const initialState = {
  initialized: false,
};

export const frontasticBridgeReducer = (state = initialState, action: AnyAction) => {
  if (action.type === 'FRONTASTIC_BRIDGE_INIT') {
    return {
      ...state,
      initialized: true,
    };
  }

  return state;
};
