class Wishlist {
  private wishlistId;
  private lineItems;
  constructor(wishlist: { wishlistId?: any; lineItems?: any[] } = {}) {
    this.wishlistId = wishlist.wishlistId || null;
    this.lineItems = wishlist.lineItems || [];
  }

  getLineItems() {
    return this.lineItems;
  }
}

export default Wishlist;
